import React from 'react';
import { H6 , Badges } from '../../AbstractElements';
import moment from 'moment'; 


const getState = (state) =>{
  switch (state) {
    case 'succeeded':
      return {value:'Success',className:'success'}
      break;

    case 'refunded':
      return {value:'Refunded',className:'warning'}
      break;
    
    case 'failed':
      return {value:'Failed',className:'danger'}
      break;  
  
    default:
      break;
  }
}

export const OrderHistoryColumns = [
  {
    name: <H6>Email ID</H6>,
    selector: (row) => row.emailId || '-',
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>First Name</H6>,
    selector: (row) => {
      console.log(row.firstName[0])
      return row.firstName?.[0] ? row.firstName[0].charAt(0).toUpperCase() + row.firstName.slice(1).toLowerCase() : ''
    },
    sortable: true
    // ? row.firstName.charAt(0).toLowerCase() :
    // center: true,
  },
  {
    name: <H6>Last Name</H6>,
    selector: (row) =>{
      return row.lastName?.[0] ? row.lastName[0].charAt(0).toUpperCase() + row.lastName.slice(1).toLowerCase() : ''
    },
    sortable: true
    // center: true,
  },
  {
    name: <H6>Reservation ID</H6>,
    selector: (row) => row.reservationId,
    sortable: true,
    center: true,
  },
  {
    name: <H6>Arrival Date</H6>,
    selector: (row) => <div className='text-center'>{row.arrivalDateTime ? row.arrivalDateTime : ''}</div>,
    sortable: true,
    center: true,
    wrap: true
  },  
  {
    name: <H6>Departure Date</H6>,
    selector: (row) => <div className='text-center'>{row.departureDateTime ? row.departureDateTime : ''}</div>,
    sortable: true,
    center: true,
    wrap: true

  },
  {
    name: <H6>Amount Charged</H6>,
    selector: (row) => row.chargeAmount ?  '$'+parseFloat(row.chargeAmount).toFixed(2) : '-',
    sortable: true,
    center: true,
  },
  {
    name: <H6>Amount Deducted</H6>,
    selector: (row) => row.deductAmount ?  '$'+parseFloat(row.deductAmount).toFixed(2) : '-',
    sortable: true,
    center: true,
  },
  {
    name: <H6>Amount Refunded</H6>,
    selector: (row) => row.refundAmount ?  '$'+parseFloat(row.refundAmount).toFixed(2) : '-',
    sortable: true,
    center: true,
  },
  {
    name: <H6>Payment Status</H6>,
    selector: (row) => row.state ?
    (
      <div className="text-end" style={{fontSize:"15px"}} >
        {/* <spam className={getState(row.state).className} >{getState(row.state).value}</spam> */}
        <Badges attrBadge={{ color: getState(row.state).className ,style:{borderRadius:'0px'} }}>{getState(row.state).value}</Badges>
      </div>
    ) : '-',
    sortable: true,
    center: true,
  },
];